<script>
import Multiselect from "@vueform/multiselect";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { reqMethods } from '../../../state/helpers';
import Swal from "sweetalert2";
import { VueFileAgent } from '@boindil/vue-file-agent-next';
import '@boindil/vue-file-agent-next/dist/vue-file-agent-next.css';
import axios from 'axios';

/**
 * Projects-create component
 */
export default {
    components: {
        Layout,
        VueFileAgent,
        PageHeader,
        Multiselect,
    },

    data() {
        return {
            latitude: null,
            longitude: null,
            markerId: 1,
            zoom: 14,
            maxZoom: 25,
            markers: [],
            markersselect: [],
            selectedPosition: {
                lat: null,
                lng: null
            },
            url: 'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            pageTitle: "Lancer une alerte",
            items: [
                {
                    text: "Dashboard",
                    href: "/",
                },
                {
                    text: "Nouvelle alerte citoyenne",
                    active: true,
                },
            ],
            niveaux: [
                { value: '0', label: 'Normal' },
                { value: '1', label: 'Urgent' },
            ],
            isRecording: false,
            mediaRecorder: null,
            chunks: [],
            audioURL: null,
            options: [],
            zones: [],
            data: {
                thematic: null,
                title: null,
                urgency_level: null,
                cover_areas: null,
                coordinate: null,
                description: null,
                messages: null,
                photos: [],
                videos: [],
                audios: [],
                audiosLocal: [],
                files: [],
            },
            filesData: [],
            Markers: [],
            spanView: false,
            spanView1: false,
            currentAlertID: null,
            reqError: null,
            response: null,
        };
    },
    methods: {
        ...reqMethods,
        async createAlert(sendBool) {
            if (sendBool) {
                this.spanView1 = true;
            } else {
                this.spanView = true;
            }
            ((this.filesData.length !== 0) ? this.filterFiles() : null);
            const formData = new FormData();
            formData.append('thematic', this.data.thematic);
            formData.append('title', this.data.title);
            formData.append('urgency_level', this.data.urgency_level);
            formData.append('cover_areas', this.data.cover_areas);
            if (this.data.coordinate === null) {
                let lat = 6.237946087528548;
                let lng = 1.203002929520065;
                const coordinate = `POINT(${lat} ${lng})`;
                this.data.coordinate = `${coordinate}`;
                formData.append('coordinates', this.data.coordinate);
            } else {
                formData.append('coordinates', this.data.coordinate);
            }
            formData.append('description', this.data.description);
            formData.append('messages', this.data.messages);
            formData.append('send', sendBool);

            const fileCategories = ['photos', 'videos', 'audios', 'files'];
            for (const category of fileCategories) {
                for (const file of this.data[category]) {
                    formData.append(category, file);
                }
            }
            const that = this;
            try {
                const response = await axios.post('https://api.alcit.sims-technologie.com/api/v1/alert/alert/', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': localStorage.getItem('token'),
                    },
                });
                if (sendBool) {
                    Swal.fire(response.data.message, "L'Alerte à été Publier!", "success");
                    that.spanView1 = false;
                } else {
                    Swal.fire(response.data.message, "L'Alerte à été Sauvegarder !", "success");
                    that.spanView = false;
                }
                this.$router.push({ path: `/alertDelail/${response.data.id}` });
            } catch (error) {
                that.spanView = false;
                that.spanView1 = false;
                that.reqError = error;
                Swal.fire({
                    title: "Oops...",
                    text: "Erreur lors de l'ajout de l'alerte",
                    icon: "error",
                    confirmButtonClass: "btn btn-primary w-xs mt-2",
                    buttonsStyling: false,
                    showCloseButton: true,
                });
            }
        },

        filterFiles() {
            this.filesData.forEach(element => {
                if (element.file.type.includes('image/')) {
                    this.data.photos.push(element.file);
                } else if (element.file.type == 'application/pdf') {
                    this.data.files.push(element.file);
                } else if (element.file.type.includes('video/')) {
                    this.data.videos.push(element.file);
                } else if (element.file.type.includes('audio/')) {
                    this.data.audios.push(element.file);
                }
            })
        },

        getThematics() {
            const that = this;
            this.getRequest('/alert/thematic/center/')
                .then(
                    function (response) {
                        response.data.forEach(element => {
                            if (element.for_citizens == true || element.for_centers == true) {
                                that.options.push({ value: element.id, label: element.name });
                            }
                        });
                    },
                    function (error) {
                        that.fetchError = error;
                    }
                )
        },

        getZones() {
            const that = this;
            this.getRequest('/account/zone/')
                .then(
                    function (response) {
                        response.data.forEach(element => {
                            that.zones.push({ value: element.id, label: element.name });
                        });
                    },
                    function (error) {
                        that.fetchError = error;
                    }
                )
        },

        async startRecording() {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
                this.mediaRecorder = new MediaRecorder(stream);
                this.mediaRecorder.addEventListener('dataavailable', this.onDataAvailable);
                this.mediaRecorder.addEventListener('stop', this.onRecordingStopped);
                this.chunks = [];
                this.mediaRecorder.start();
                this.isRecording = true;
            } catch (error) {
                console.error('Erreur lors de la demande d\'accès à l\'audio:', error);
            }
        },

        async convertBlobToAudioFile(blobUrl) {
            const response = await fetch(blobUrl);
            const blob = await response.blob();

            const currentDate = new Date();
            const fileName = currentDate.toLocaleString('fr-FR', { timeZone: 'UTC' })
                .replace(/[/:]/g, '-') + '.wav';

            const file = new File([blob], fileName, { type: 'audio/wav' });
            return file;
        },

        stopRecording() {
            this.mediaRecorder.stop();
            this.isRecording = false;
        },

        onDataAvailable(event) {
            event.data.size > 0 ? this.chunks.push(event.data) : null;
        },

        async onRecordingStopped() {
            const audioBlob = new Blob(this.chunks, { type: 'audio/wav' });
            const audioURL = URL.createObjectURL(audioBlob);
            this.data.audiosLocal.push(audioURL);
            const audioFile = await this.convertBlobToAudioFile(audioURL);
            this.data.audios.push(audioFile);
        },

        deleteAudio(index) {
            this.data.audiosLocal.splice(index, 1);
            this.data.audios.splice(index, 1);
        },
        onMapClick(event) {
            console.log(event);
            if (event && event.latLng) {
                const lat = event.latLng.lat()
                const lng = event.latLng.lng()
                const coordinate = `POINT(${lat} ${lng})`;
                this.selectedPosition.lat = lat;
                this.selectedPosition.lng = lng;
                if (this.data.coordinate === null) {
                    this.data.coordinate = `${coordinate}`;
                } else {
                    this.data.coordinate += `,${coordinate}`;
                }
                this.markersselect.push({ position: { lat: lat, lng: lng, title: "Position selectionnée" } });
            }
        },

        handleMapClick(event) {
            if (event && event.lat) {
                const { lat, lng } = event;
                const coordinate = `POINT(${lat} ${lng})`;

                if (this.data.coordinate === null) {
                    this.data.coordinate = `${coordinate}`;
                } else {
                    this.data.coordinate += `,${coordinate}`;
                }

                const newMarker = {
                    id: this.markerId++,
                    position: { lat, lng },
                };
                this.Markers.push(newMarker);
            }
        },

        handleMarkerClick(marker) {
            const markerIndex = this.Markers.findIndex(m => m.id === marker.id);
            if (markerIndex !== -1) {
                this.Markers.splice(markerIndex, 1);
                this.data.coordinate = null;
                this.Markers.forEach(mark => {
                    const coordinate = `POINT(${mark?.position?.lat} ${mark?.position?.lng})`
                    if (this.data.coordinate === null) {
                        this.data.coordinate = `${coordinate}`;
                    } else {
                        this.data.coordinate += `,${coordinate}`;
                    }
                });
            }
        },
    },
    mounted() {
        const that = this;
        !navigator.mediaDevices.getUserMedia ? alert('Votre navigateur ne supporte pas l\'enregistrement audio.') : null;
        this.getThematics();
        this.getZones();
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(function (position) {
                that.latitude = position.coords.latitude;
                that.longitude = position.coords.longitude;
            }, function (error) {
                alert("Erreur de géolocalisation : " + error.message);
            });
        } else {
            alert("La géolocalisation n'est pas prise en charge par ce navigateur.");
        }
    },
};
</script>

<template>
    <Layout>
        <PageHeader :title="pageTitle" :items="items" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <form>
                            <div class="form-group row mb-4">
                                <label for="title" class="col-form-label col-lg-2">Thématique</label>
                                <div class="col-lg-10">
                                    <multiselect class="test-primary" v-model="data.thematic" :options="options">
                                    </multiselect>
                                </div>
                            </div>
                            <div class="form-group row mb-4">
                                <label for="title" class="col-form-label col-lg-2">Titre</label>
                                <div class="col-lg-10">
                                    <input id="title" name="title" v-model="data.title" type="text" class="form-control"
                                        placeholder="Saisir le titre de l'alerte..." />
                                </div>
                            </div>
                            <div class="form-group row mb-4">
                                <label for="title" class="col-form-label col-lg-2">Niveau d'urgence</label>
                                <div class="col-lg-10">
                                    <multiselect class="test-primary" v-model="data.urgency_level" :options="niveaux">
                                    </multiselect>
                                </div>
                            </div>
                            <div class="form-group row mb-4">
                                <label for="title" class="col-form-label col-lg-2">Zones de couverture</label>
                                <div class="col-lg-10">
                                    <multiselect class="test-primary" v-model="data.cover_areas" :options="zones"
                                        mode="tags">
                                    </multiselect>
                                </div>
                            </div>
                            <div class="form-group row mb-4">
                                <label for="projectdesc" class="col-form-label col-lg-2">Message</label>
                                <div class="col-lg-10">
                                    <textarea id="projectdesc" v-model="data.description" class="form-control" rows="4"
                                        placeholder="Donnez une desciption détaillée de l'alerte..."></textarea>
                                </div>
                            </div>
                            <div class="form-group row mb-4">
                                <label class="col-form-label col-lg-2">Enregistrer audio</label>
                                <div class="col-lg-10">
                                    <div class="audio-recorder">
                                        <div class="recorder-controls">
                                            <button type="button" @click="startRecording" :disabled="isRecording"
                                                :class="{ 'record-button': true, 'pulse': isRecording }">
                                                <span v-if="!isRecording"><i class="mdi mdi-microphone-outline"></i></span>
                                                <span v-else><i class="mdi mdi-microphone-settings"></i></span>
                                            </button>
                                            <button type="button" @click="stopRecording" :disabled="!isRecording"
                                                class="stop-button"><i class="mdi mdi-stop"></i>
                                            </button>
                                        </div>
                                        <div class="audio-player" v-if="data.audiosLocal.length > 0">
                                            <div class="audio-item" v-for="(audio, index) in data.audiosLocal" :key="index">
                                                <audio :src="audio" controls></audio>
                                                <button type="button" @click="deleteAudio(index)" class="delete-button">
                                                    <i class="mdi mdi-close"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row mb-4">
                                <label class="col-form-label col-lg-2">Fichiers associés</label>
                                <div class="col-lg-10">
                                    <VueFileAgent :deletable="true" :maxSize="'150MB'" :maxFiles="20" :meta="true"
                                        :multiple="true" :accept="'image/*,video/*,audio/*,.pdf'" :uploadUrl="uploadUrl"
                                        v-model="filesData" />
                                </div>
                            </div>
                            <div class="form-group row mb-4">
                                <label for="title" class="col-form-label col-lg-2">Coordonnées géographique</label>


                                <div class="col-lg-10">
                                    <GMapMap :center="{ lat: 6.148060326719236, lng: 1.2232706333794041 }" :zoom=10
                                        ref="myMarker" map-type-id="terrain" style="width: 100%; height: 900px"
                                        @click="onMapClick" :options="{
                                            zoomControl: true,
                                            mapTypeControl: true,
                                            scaleControl: true,
                                            streetViewControl: true,
                                            rotateControl: true,
                                            fullscreenControl: true
                                        }">
                                        <GMapMarker :key="index" v-for="(ms, index) in markersselect"
                                            :position="ms.position" :clickable="true" :draggable="false"
                                            @click="handleMapClick(ms.position)" :icon='{
                                                url: "/maps/position.svg",
                                                scaledSize: { width: 62, height: 62 },
                                                labelOrigin: { x: 16, y: -10 }
                                            }'>
                                            <GMapInfoWindow :opened="true">
                                                <div>{{ ms.position.title }}
                                                    <MyComponent />
                                                </div>
                                            </GMapInfoWindow>
                                        </GMapMarker>
                                    </GMapMap>

                                </div>
                            </div>

                            <div class="row justify-content-end">
                                <div class="col-md-10">
                                    <button type="button" @click="createAlert(false)" title="Ne pas diffuser maintenant"
                                        class="btn btn-primary" :disabled="spanView">
                                        Sauvegarder l'alerte
                                        <span v-if="spanView" class="spinner-border spinner-border-sm"></span>
                                    </button>
                                    <button type="button" @click="createAlert(true)" class="btn btn-success"
                                        style="float: right" :disabled="spanView1">
                                        <span v-if="spanView1" class="spinner-border spinner-border-sm"></span>
                                        Diffuser l'alerte
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <!-- end row -->
    </Layout>
</template>
  
<style scoped>
.recorder-controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.pulse:before {
    content: '';
    display: block;
    position: absolute;
    width: 50px;
    height: 50px;
    top: 0;
    left: 0;
    background-color: inherit;
    border-radius: inherit;
    transition: opacity .3s, transform .3s;
    animation: pulse-animation 1s cubic-bezier(0.24, 0, 0.38, 1) infinite;
    z-index: 1;
}

@keyframes pulse-animation {
    0% {
        opacity: 1;
        transform: scale(1);
    }

    50% {
        opacity: 0;
        transform: scale(1.5);
    }

    100% {
        opacity: 0;
        transform: scale(1.5);
    }
}

.record-button {
    background-color: #28a745;
    position: absolute;
    color: white;
    border: none;
    border-radius: 90px;
    padding: 8px 16px;
    cursor: pointer;
    width: 50px;
    height: 50px;
    top: 0;
    right: -50;
}

.stop-button {
    background-color: #dc3545;
    position: absolute;
    color: white;
    border: none;
    border-radius: 90px;
    padding: 8px 16px;
    cursor: pointer;
    width: 50px;
    height: 50px;
    top: 0;
    right: 0;
}

.audio-player {
    width: 100%;
}

.audio-item {
    display: flex;
    justify-content: center;
    margin-bottom: 5px;
}

.delete-button {
    background-color: #dc3545;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 5px 10px;
    margin-left: 10px;
    cursor: pointer;
}
</style>
  